import { useEffect, useState } from "react";
import React from "react";
import axios from "axios";
import { Toast, Form, Input } from "antd-mobile";
import MobileNumber from "components/mobile-number";
import Password from "components/password";
import Submit from "components/submit";
import VerificationCode from "components/VerificationCode";
// import MobileNumber from "../../components/mobile-number";
import "./index.scss";

const getUrlParams = () => {
  let obj = {};
  var query = window.location.search.substring(1);
  console.log(window.location)
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    obj[pair[0]] = pair[1];
  }
  return obj;
};

// axios.interceptors.request.use(function (config) {
//   // 在发送请求之前做些什么
//   console.log(33333333333333, config)
//   return config;
// }, function (error) {
//   // 对请求错误做些什么
//   console.log(22222222222222, error)
//   return Promise.reject(error);
// });

// axios.interceptors.response.use(function (response) {
//   // 2xx 范围内的状态码都会触发该函数。
//   // 对响应数据做点什么
//   return response;
// }, function (error) {
//   // 超出 2xx 范围的状态码都会触发该函数。
//   // 对响应错误做点什么
//   console.log(111111111111, error)
//   return Promise.reject(error);
// });

const Login = () => {
  // console.log("链接获取到的结果是什么-----", getUrlParams())
  const [isRegister, setIsRegister] = useState(false); // 顶部tab
  const [registerVisible, setRegisterVisible] = useState(false); // 账号是否已注册
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [code, setCode] = useState("");
  const [submitVisible, setSubmitVisible] = useState(false);
  // const [post, setPost] = useState(null);

  // const baseURL = "http://106.15.104.248/v3";   // 开发环境
  // const baseURL = "https://api.deye.com.cn/v3"; // 生产环境
  const baseURL = window.location.origin
  const appid = "a774310e-a430-11e7-9d4c-00163e0c1b21";
  console.log(1111111111111111, getUrlParams())

  const submit = () => {
    if (!/^[1][3-9][0-9]{9}$/.test(mobile)) {
      Toast.show("手机号输入不正确");
      return;
    }
    if (password.length < 6) {
      Toast.show("密码输入不正确");
      return;
    }
    const resUrl = () => {
      let url = baseURL + '/v3/enduser_oauth2/jd/authorize?'
      // let url = baseURL + '/?'
      for (let key in getUrlParams()) {
        url += key + '=' + getUrlParams()[key] + '&'
      }
      url = url.substring(0, url.length - 1)
      return url

    }


    axios
      .post(
        // `${baseURL}/enduser_oauth2/jd/authorize?${client_id}&${response_type}&${redirect_uri}&${state}`,
        resUrl(),
        {
          appid,
          loginname: mobile,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8",
          },
        }
      )
      .then((res) => {
        // setPost(response.data);
        console.log("登录后返回------", res, res.status);
        if (res.data?.location) {
          // window.close()
          window.location.href = res.data?.location
        } else if (res.data?.error === '10000') {
          Toast.show(res.data?.error_description);
        }
        // if (res.data.meta.code === 10104) {
        //   Toast.show("多用户登录");
        // } else if (res.data.meta.code === 0) {
        //   Toast.show("登录成功");
        // }
      })
      .catch((e) => {
        console.log(e)

      })
      ;
  };
  // const onFinish = (values) => {
  //   console.log("onFinish-------", values);
  // };
  const getCode = () => {
    axios
      .post(`${baseURL}/v3/enduser/getVerCode/`, {
        appid,
        loginname: mobile,
      })
      .then((res) => {
        // setPost(response.data);
        console.log("获取到验证码后的返回值------", res);
        if (res.data.data.type === "1") {
          Toast.show("该账号已注册");
          setRegisterVisible(true);
        }
        if (res.data.meta.code === 10250) {
          Toast.show("找不到对应app");
        } else if (res.data.meta.code === 10000) {
          Toast.show("手机号输入不正确");
        }
      });
  };
  const register = () => {
    if (!/^[1][3-9][0-9]{9}$/.test(mobile)) {
      Toast.show("手机号输入不正确");
      return;
    }
    if (password.length < 6) {
      Toast.show("密码输入不正确");
      return;
    }
    if (registerVisible) {
      Toast.show("该账号已注册");
      return;
    }
    axios
      .post(`${baseURL}/v3/enduser/checkVerCode/`, {
        appid,
        loginname: mobile,
        vercode: code,
        password: password,
      })
      .then((response) => {
        if (response.data.meta.code === 10252) {
          Toast.show("用户被禁用");
          return;
        } else if (response.data.meta.code === 10000) {
          Toast.show("手机号输入不正确");
          return;
        } else if (response.data.meta.code === 10250) {
          Toast.show("找不到对应app");
          return;
        } else if (response.data.meta.code === 10301) {
          Toast.show("验证码错误");
          return;
        } else if (response.data.meta.code === 10300) {
          Toast.show("验证码过期");
          return;
        }
        console.log("校验验证码成功后返回", response, response.data.data);
        window.localStorage.setItem("csrf_token", response.data.data.token);
        axios
          .post(
            `${baseURL}/v3/enduser/resetPassword/`,
            {
              password1: password,
              password2: password,
            },
            {
              headers: {
                authorization: "jwt " + response.data.data.token,
              },
            }
          )
          .then((res) => {
            console.log("注册成功后返回", res);
            if (res.data.meta.code === 0) {
              Toast.show("注册成功");
              setIsRegister(false);
            }
          });
      });
  };

  useEffect(() => {
    if (isRegister) {
      if (
        mobile.length !== 0 &&
        password.length !== 0 &&
        code.length !== 0
        //  &&
        // registerVisible === false
      ) {
        setSubmitVisible(true);
      }
    } else {
      if (mobile.length !== 0 && password.length !== 0) {
        setSubmitVisible(true);
      }
    }
  }, [mobile, code, password]);
  // 数据清理
  useEffect(() => {
    setMobile("");
    setPassword("");
    setCode("");
    setSubmitVisible(false);
  }, [isRegister]);
  return (
    <div className="login-container">
      <div className="type">
        <div
          className={`type-title ${isRegister === false ? "active" : ""}`}
          onClick={() => setIsRegister(false)}
        >
          账号登录
        </div>
        <div className="type-line"></div>
        <div
          className={`type-title ${isRegister === false ? "" : "active"}`}
          onClick={() => setIsRegister(true)}
        >
          账号注册
        </div>
      </div>
      {isRegister === false ? (
        <>
          <MobileNumber mobile={mobile} setMobile={setMobile} />
          <Password password={password} setPassword={setPassword} />
          <Submit visible={submitVisible} onPress={submit} />
        </>
      ) : (
        // <>
        //   <Form
        //     form={form}
        //     // onFinish={onFinish}
        //     footer={<Submit visible={true} onPress={submit} />}
        //     onValuesChange={(value) => {
        //       console.log("字段更新时的value", value);
        //     }}
        //   >
        //     <Form.Item
        //       className="mobile-box"
        //       name="mobile"
        //       rules={[{ message: "请输入手机号" }]}
        //     >
        //       <Input
        //         placeholder="请输入注册手机号"
        //         className="mobile-box-input"
        //       />
        //     </Form.Item>
        //     <Form.Item
        //       className="mobile-box"
        //       name="password"
        //       rules={[{ message: "请输入密码" }]}
        //     >
        //       <Input
        //         placeholder="密码长度6-18个字符"
        //         className="mobile-box-input"
        //       />
        //     </Form.Item>
        //   </Form>
        // </>
        <>
          <MobileNumber mobile={mobile} setMobile={setMobile} />
          <VerificationCode
            code={code}
            setCode={setCode}
            mobile={mobile}
            onPress={getCode}
          />
          <Password
            password={password}
            setPassword={setPassword}
            placeholder="新密码长度6-18个字符"
            noOption={false}
          />
          <Submit visible={submitVisible} onPress={register} text="注册" />
        </>
      )}
    </div>
  );
};
export default Login;
