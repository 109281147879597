import { Toast, Input } from "antd-mobile";
import { useEffect, useState } from "react";
import "./index.scss";

const Password = ({
  password,
  setPassword,
  placeholder = "密码长度6-18个字符",
  noOption = true,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [iconSize, setIconSize] = useState(10);

  useEffect(() => {
    if (!noOption) {
      setPasswordVisible(true);
    }
  }, [noOption]);
  useEffect(() => {
    if (passwordVisible === true) {
      setIconSize(17);
    } else {
      setIconSize(10);
    }
  }, [passwordVisible]);
  return (
    <div className="password-box">
      {/* type="tel" */}
      <input
        className="password-box-input"
        value={password}
        type={`${passwordVisible === true ? "text" : "password"}`}
        placeholder={placeholder}
        maxLength={18}
        minLength={6}
        onChange={({ target }) => {
          setPassword(target.value);
        }}
      ></input>
      {noOption ? (
        <span
          className={`iconfont ${
            passwordVisible === true ? "icon-xianshi" : "icon-yincang"
          }`}
          style={{ fontSize: iconSize + "px", color: "#CDD1D6" }}
          onClick={() => {
            setPasswordVisible(!passwordVisible);
          }}
        ></span>
      ) : null}
    </div>
  );
};
export default Password;
