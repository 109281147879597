import { useState, useEffect } from "react";
import { Toast } from "antd-mobile";
import dayjs from "dayjs";
import "./index.scss";

const TimeInterval = 60;
const VerificationCode = ({ code, setCode, mobile, onPress = () => {} }) => {
  const [send, setSend] = useState(false);
  const [leftTime, setLeftTime] = useState(10000);
  const [loginMessageTime, setLoginMessageTime] = useState(0);

  const sendMessage = () => {
    if (!mobile) {
      Toast.show("请先输入手机号");
      return;
    }
    if (!/^[1][3-9][0-9]{9}$/.test(mobile)) {
      Toast.show("请输入正确的手机号");
      return;
    }
    // setNoSend(true);
    setLeftTime(TimeInterval);
    const nowTime = dayjs().format();
    setLoginMessageTime(dayjs(nowTime).unix());
    onPress();
    setSend(true);
  };
  useEffect(() => {
    const getLeftTime = () => {
      if (loginMessageTime) {
        const nextTime = dayjs().format();
        const value = parseInt(dayjs(nextTime).unix() - loginMessageTime);
        if (value > 61) {
          return;
        }
        if (TimeInterval - value >= 0) {
          setLeftTime(TimeInterval - value);
        } else {
          setLeftTime(10000);
        }
      }
    };
    getLeftTime();
    const key = setInterval(() => {
      getLeftTime();
    }, 1000);
    return () => {
      clearInterval(key);
    };
  }, [loginMessageTime]);

  return (
    <div className="code-box">
      {/* type="tel" */}
      <input
        className="code-box-input"
        value={code}
        type="number"
        placeholder={"请输入验证码"}
        onChange={({ target }) => {
          if (target.value.length <= 6) {
            setCode(target.value);
          }
        }}
      ></input>
      {leftTime <= TimeInterval ? (
        <span className="code-box-tip">重新发送{leftTime}s</span>
      ) : (
        <span
          className="code-box-button"
          onClick={() => {
            sendMessage();
          }}
        >
          {send ? "重新发送" : "发送验证码"}
        </span>
      )}
    </div>
  );
};
export default VerificationCode;
