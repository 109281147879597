import "./index.scss";
const Submit = ({ visible, text = "登录", onPress = () => {} }) => {
  return (
    <div className="submit">
      {visible ? (
        <div className="submit-touch" onClick={onPress}>
          <div className="submit-touch-text">{text}</div>
        </div>
      ) : (
        <div className="submit-gray">
          <div className="submit-gray-text">{text}</div>
        </div>
      )}
    </div>
  );
};
export default Submit;
