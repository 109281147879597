import { Toast, Input } from "antd-mobile";
// import { Toast, Cell } from 'react-vant'
import { useEffect, useState } from "react";
import "./index.scss";

const MobileNumber = ({ mobile, setMobile }) => {
  // const isMobile = (value) => {
  //   return /^[1][3-9][0-9]{9}$/.test(value);
  // };
  // useEffect(() => {
  //   if (mobile.length === 11) {
  //     if (!isMobile(mobile)) {
  //       Toast.show({
  //         content: "手机号输入不正确",
  //       });
  //     }
  //   }
  // }, [mobile]);
  return (
    <div className="mobile-box">
      {/* type="tel" */}
      <input
        className="mobile-box-input"
        value={mobile}
        type="number"
        placeholder={"请输入手机号"}
        maxLength={11}
        onChange={({ target }) => {
          if (target.value.length <= 11) {
            setMobile(target.value);
          }
        }}
      ></input>
      {mobile?.length >= 1 ? (
        <span
          className="iconfont icon-cha"
          style={{ fontSize: 10 + "px", color: "#CDD1D6" }}
          onClick={() => {
            setMobile("");
          }}
        ></span>
      ) : null}
    </div>
  );
};
export default MobileNumber;
